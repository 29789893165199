.menu span:nth-child(1) {
  transform-origin: 0% 0%;
  transform: rotate(45deg);
}
.menu span:nth-child(2) {
  opacity: 0;
}
.menu span:nth-child(3) {
  transform-origin: 0% 100%;
  transform: rotate(-45deg);
}
