.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 60vh;
  object-fit: cover;
}

.swiper-button-prev {
  color: #facd15;
  width: 60px;
}

.swiper-button-next {
  color: #facd15;
  width: 60px;
}

.swiper-pagination {
  margin-bottom: 10px;
}

.swiper-pagination-bullet {
  background-color: rgb(54, 54, 54) !important;
  width: 0.8rem;
  height: 0.8rem;
}

.autoplay-progress {
  width: 100%;
  height: 10px;
  background-color: #e0e0e0;
}

.progress-bar {
  width: 0;
  height: 100%;
  background-color: #222222;
  transition: width 0.1s linear;
}
