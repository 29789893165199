@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .transition-fast {
    @apply transition-all duration-200;
  }
  .transition-midfast {
    @apply transition-all duration-300;
  }
  .transition-medium {
    @apply transition-all duration-500;
  }
  .transition-slow {
    @apply transition-all duration-1000;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

*:focus-visible {
  outline: none !important;
}

*:focus {
  outline: none !important;
}

body {
  overflow-x: hidden;
}

select {
  appearance: none;
  -webkit-appearance: none;
  width: 100%;
  /* Espaço para a seta */
  padding-right: 2rem !important;
  /* Seta do select */
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 5"><path d="M0 0l5 5 5-5H0z" fill="%23555"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 12px;
}

a {
  text-decoration: none;
}

@media (max-width: 1020px) {
  /* html {
    font-size: 14px;
  } */
}

@media (max-width: 290px) {
  /* html {
    font-size: 10px;
  } */
}

select::after {
  content: ' ';
  position: absolute;
  top: 50%;
  margin-top: -2px;
  right: 8px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid white;
}

.remove-arrows::-webkit-outer-spin-button,
.remove-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.remove-arrows {
  -moz-appearance: textfield;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.MuiMenu-paper.MuiMenu-paper.css-1fzpvlh-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  @media (max-width: 760px) {
    max-width: 100% !important;
    width: 100%;
    left: 0 !important;
  }
}

progress {
  -webkit-appearance: none;
  appearance: none;
}

progress::-webkit-progress-bar {
  background-color: #e0e0e0;
}

progress::-webkit-progress-value {
  background-color: #222222;
}

/* Estilizando a barra de rolagem com a classe scroll-news-home */
.scroll-news-home::-webkit-scrollbar {
  width: 8px; /* Largura da barra de rolagem */
  height: 8px; /* Altura da barra de rolagem horizontal (se necessário) */
}

.scroll-news-home::-webkit-scrollbar-thumb {
  background-color: #b0b0b0; /* Cor do polegar (cinza) */
  border-radius: 10px; /* Cantos arredondados */
}

.scroll-news-home::-webkit-scrollbar-track {
  background-color: #e0e0e0; /* Cor de fundo da trilha (cinza claro) */
  border-radius: 10px; /* Cantos arredondados da trilha */
}

.scroll-news-home::-webkit-scrollbar-button {
  display: none; /* Remove os botões de rolagem */
}
