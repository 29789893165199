@tailwind base;
@tailwind components;
@tailwind utilities;

.adjust-content-bf {
  @apply relative min-h-screen;
}

.link-header {
  @apply px-2 text-lg text-black transition-colors duration-200 lg:px-3 lg:py-1 2xl:px-3 3xl:px-6 2xl:py-2 text-nowrap;
}

.link-header-not-select {
  @apply hover:text-yellow-400;
}

.link-header-select {
  @apply text-yellow-400;
}

.link-news {
  @apply border-zinc-300 text-zinc-500 transition-colors;
}

.link-news-not-select {
  @apply hover:border-zinc-300 hover:bg-zinc-300 hover:text-zinc-700;
}

.link-news-select {
  @apply bg-zinc-300;
}
